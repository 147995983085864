@import "fonts";
@import "animation";
@import "footer";
@import "typography";

html, body {
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
}
