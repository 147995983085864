// Good helper for fonts: https://google-webfonts-helper.herokuapp.com

// Montserrat

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('./assets/montserrat-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/montserrat-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/montserrat-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
