@import "typography";

.footer {
  position: absolute;
  bottom: 0;
  z-index: 5000;
  width: 100%;

  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  color: white;


  &__heading {
    @include Subheading();
    margin-bottom: 16px;
    text-align: center;
  }

  &__links {
    display: flex;
    justify-content: center;
  }

  &__link {
    width: 32px;
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    transition: all ease-in-out 75ms;
    will-change: transform, margin-left, margin-right;

    &:hover, &:focus {
      transform: scale(1.5);
      margin-left: 24px;
      margin-right: 24px;
    }

    &_twitter {
      background-image: url('./assets/logo_twitter.svg');
    }

    &_telegram {
      background-image: url('./assets/logo_telegram.svg');
    }

    &_github {
      background-image: url('./assets/logo_github.svg');
    }

    &_email {
      background-image: url('./assets/logo_email.svg');
    }
  }
}
