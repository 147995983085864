$font-fallback: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

@mixin FontHeading() {
  font-family: Montserrat, $font-fallback;
  font-weight: 400;
}

@mixin Subheading {
  @include FontHeading();
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: 1px;
  font-size: 20px;
}
